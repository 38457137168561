body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.back {
  background: #021040;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.div-center {
  width: 400px;
  height: 400px;
  color: white;
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;

  display: table;
}

div.content {
  display: table-cell;
  vertical-align: middle;
}

.login-form-forgot {
  float: right;
  color: #fff;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

.ant-btn-primary {
  border-color: #0e9fa0 !important;
  background-color: #0e9fa0 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #0e9fa0 !important;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo-main {
  float: left;
  width: 120px;
  height: 31px;
  margin: 0 24px 16px 0;
}

.logo-main img {
  height: 50px;
}

.ant-row-rtl .logo-main {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-layout-header {
  color: #fff;
}

a h5 {
  color: #007bff;
}

/* .ant-steps-item-title {
  display: none;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background-color: #d0cfcf !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #d0cfcf !important;
} */
.signature {
  display: block;
  margin: 10px auto;
  border: 2px solid black;
  width: 100%;
  height: 200px;
}

.antd-layout-content {
  margin: 30px 15px 0px;
  padding: 0px 50px;
  min-height: 74vh;
}

.ant-layout-header {
  height: 64px;
  padding: 0 25px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.cursor-pointer {
  cursor: pointer;
}

.pageloading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  backdrop-filter: blur(5px);
}

[data-theme="compact"] .site-navigation-steps,
.site-navigation-steps {
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

@media (max-width: 767px) {
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    display: none !important;
  }
  .ant-steps-small .ant-steps-item-icon {
    margin: 0 !important;
  }
  .ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin: 0 !important;
  }
}

@media (max-width: 576px) {
  .antd-layout-content {
    margin: 24px 16px 0px;
    padding: 0px;
    min-height: 80vh;
  }

  .ant-steps-item-title {
    display: none;
  }

  .ant-layout-header {
    padding: 0 15px;
  }

  .logo-main img {
    height: 45px;
  }
  .site-layout-content {
    padding: 15px 0;
  }
}
